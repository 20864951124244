import { sample } from 'lodash';
import { Dimensions, Platform, PlatformIOSStatic } from 'react-native';

export const width = Dimensions.get('window').width;
export const height = Dimensions.get('window').height;

export const aspectRatio = 2 / 3;

export const heightForImages = 3 / 2;

export const admins = {
	crSf0l73sLeYBS39n1afeZtcROV2: 'Alec',
	hIcBtjSttwWXxqaPGsGAEo9dPVm1: 'Alec',
	Krl0StX1tRPFJk20bMxE3G8CDcH2: 'Will',
	qUsCHuY5azN7KJRG8fT5GxJ0jAZ2: 'Liv',
	kGORLrtHzEPL1LQ5IYI96IgCxHE3: 'Austin',
	OOrgqGSqDvfXY9DKnS4ICFucR7o1: 'Brie',
	'1iU5V3gRSpR7LDILJlXSYakRgoa2': 'Hannah',
	lgrhTzasZQPExf1HdorjzOcWrDV2: 'Julie',
	'4ZdqabaUscS0SQrW7MLGbyqmI4x1': 'Alec in Training',
	iRFpoMxaobRG5cqyBePv8ruHOWx1: 'Sara',
	SpeTgoGziBgfZ6TAYl9ZK4ItZEe2: 'Jonathan',
	uks67o6KiDR0LxqOfktHpZMg2582: 'Morgan',
	II7tcOCIcoecWst1AzJQ61XMFut1: 'Anna',
	qW9oOzmq7oaGx7Zo3XKvKC09Tpk1: 'Will S.',
	tQ43iPKUjwSs5Ucfp0L6DlicPGC3: 'Claire B.',
	'0bve1ojpJOR0GaQGzQdqe3NBEfP2': 'Emma C.',
	Gy0f8F7hd6ZmMcmao1WtzhWRsIK2: 'Lindsey K.',
	wpHTfG1JE4T5Mr2hpyi8OsaYmB62: 'Lindsey K.',
	NJP2Y30y8Ger4IFMuX9ezihGfXz2: 'Coco C.',
	CksXkOg6M3UIbUiUdw1dQQsqI0Q2: 'Danielle M.',
	Aq1lVZuVWaaL9aa44mJdkHFs8pn1: 'Ilana A.',
	sv9tHiTxQ8hoJ7UlHH8L1JSQxh82: 'Andrea W.',
	'3incuiFdlkeYZFWf3KKFdWhzUMf2': 'Carly W',
	oglOX5IFPBgqSGNhW9MGGyKC6pA3: 'Stella G.',
	k589KcYhQseYxQDxcdGFwF4gK1d2: 'Grant C.',
	LP7h0cQamqU2sA5OSFQusi9K35C2: 'Marleigh F.',
	Zbya3JcPaGfYlZo8rqOCe3XfDlo1: 'Emma F.',
	vWw0kIs6E0ZOY9Kt9TjmRdGSJAu1: 'Lexi K.',
	gmzTTIPtbxVYvbyF9wFqsXgTrPv2: 'Alana K.',
	pade8MudFzS3DgXIFkoEPfTqKIy2: 'Gavriella G.',
	'3hIgG2AYR5Od3sj2qIl15fWKU663': 'AJ',
	kIIZwggpBVQAULZPJzlcKPKIwX52: 'Dani B.',
	gufknct3HJfiNKnucOPF10yVo312: 'Devon R.',
	ykFqF8Yscveib5IS55dzMbHqnop1: 'Michael V.',
	eplhNrEicPVNgiS8L8BXGLytXQU2: 'Reese D.',
	DgyrTPrUPKMKEUmPsVNzyAHHYEW2: 'Leena K.',
	t7BTGCFhjYaz3a87yJvPqHHI07a2: 'Sam Karshenboym',
	zUHCbHFHtIb8sUOUwPZSUlvJWCF3: 'Jessica Lee',
	m45xvWWpLySZmTVunowiZETbsOm2: 'Katelyn Donick',
	'0aS5LPylGkP1d6hN71cugGxRuDn2': 'Victoria Pham',
	H5O1wl9tjdf1K16UEGFe3uye4N42: 'Debra Frenkel',
	I5XBqwDy6ogf6zVy8ukNMLTz2622: 'Olivia Pandolfo',
	KofDTGvJJWNzOuPhfCfrvM7bPYV2: 'Will Klein',
	whNMxwHsBAMflTLGTUd0S8DIAXR2: 'Blake Fonberg',
	yAVdMLqXdWTz8ruaRt1ZLgEsMkj2: 'David S.',
	K6fkrkQS8thZZZHmX8OBtj5WeDX2: 'Sabrina Scheinberg',
	GeK6crEkTKbGoDtMjaODTYsgE813: 'Skylar Scharer',
	s1DXDSOaEWf0Kh0RWsICUmbTgMz2: 'Gaby Weinwurzel',
	sILfFVSwWhY3R206hYqAyab9OME2: 'Julia Mendelsohn',
	gFHx1Agv29WtufvsRIShgjTKoT93: 'Michelle Chien',
	'9hIaAy4O57XNS3mKpyDMYaa6QME3': 'Teera Somsak',
	'2bfdfTJw8Zh8b1m1bjwWTBbELFo2': 'Shannon Park',
	r9VeWvPxBSVg8XJ2Yl5W5qLlBnn1: 'Tali Benchimol',
	zxYLhvECAmd7rRLLU4XuFs1GN6H3: 'Keren Karshenboym',
	RuSaPFEXtiMaFZzWcMaBPmfUKXC2: 'Lisa Kevitch',
	b5mJejPuE6fvruRl5xzSFfKPqcB2: 'Nadia Jagessar',
	w7q1kSKqK5Xi0FbfANfuwMLawqk1: 'Harmonie Krieger',
	'9aO14ls1eDOhZy6uL7ZXwQDuOXf2': 'Dayna Brand',
	DBpRGemP8VPiJutHm2r2JozR41c2: 'Meghna Upadhyay',
};

export const adminLoxClubProfiles = [
	'BYucGxTxFkUNQMiFjKdmYjOdHIj2',
	'vamVV82miEd1qWwkVn7UmstuIx42',
	'b2EzPOyaHLfi7zs7fjwUoOEZuVg2',
	'9m6NNqoIQQTFbuVNaYtsMu1G77N2',
	'5WLj613xuFWGPRm5j85FZbUvy0l1',
	'YMwUapWn7GWbJDFstZwb49Z8fIy1',
	'SktgXYs2LcNndfmJreO0VZ4ANnl1',
	'GhlWeyJYexTltfIZ2Md2ppf2mqQ2',
	'n9PhOEXaTTVZzcJ6cwjUi7x2agt1',
	'b0yu1vRuGnR8KoTOzG1PtGfB97i1',
	'vgtosscjuka8OUk0sLQlULbC6ja2',
	'sEn7NlAJPFOmmN2UpADxXVDcXhn2',
	'bgkceqCRgRaEM0yBE7gxJe2XSG12',
	'uJn2gFt9U1gQCS2OTH0A70Uy2aS2',
	'x9tHqvbw5pb3U3xKoLnsLkr9WbM2',
	'odFZ4PLUDnhDnCxXB57ru3H1uii1',
	'ShuufzhSVuTjpSjeuDB3zPrVDuf2',
	'ldt3Mqq9n3XdnCkZoP3BHsO4Q9y1',
	'GV9dwNpNovP9PMSije9XJ0AXs6X2',
	'RPgvfrgvUJNJtaDkgXcMwql1lJr2',
	'cjXUES3PqyZ0uUSdcqaDIpH82bW2',
	'MjntJR3369OD2mQsR1cE4xcda4q1',
	'JL0LsehYPySbGJC4miEMuMoBSJi2',
	'RjMKag8KG4WQ9MB0rUmJFb4U66E2',
	'95L6P5BObIQxBf0KpJm2JHfF4XB2',
	'ET2KKkhzoaQciq1uJZk30PlnNdA2',
	'5qNdoBWkSWe9vfHbnW2qFvH61Ui1',
	'H84Gx64ZYDXGZhtKY5xwgOP1DSO2',
	'yPkI2AnZmcRzRXl0wcLwQmwUC7h1',
	'uNZOxCKBZ8cq3Knm9FUcZ5A6iTD3',
];

export function isIphoneX() {
	const dimen = Dimensions.get('window');
	return (
		Platform.OS === 'ios' &&
		!Platform.isPad &&
		!Platform.isTV &&
		(dimen.height === 812 || dimen.width === 812)
	);
}

export function isIphoneXS() {
	return (
		Platform.OS === 'ios' && !Platform.isPad && !Platform.isTVOS && (height > 812 || width > 812)
	);
}

export function ifIphoneX(iphoneXStyle, regularStyle) {
	if (isIphoneX()) {
		return iphoneXStyle;
	}
	return regularStyle;
}

export function whichIphone() {
	switch (height) {
		case 568:
			return 'iPhone 5, 5s, 5c, SE';
		case 667:
			return 'iPhone 6, 6s, 7, 8';
		case 812:
			return 'iPhone X';
		case 896:
			return 'iphone Xs';
	}
}

export function getPictureHeight() {
	if ((Platform as PlatformIOSStatic).isPad) return '_1280x1920';
	else if (height > 660) return '_640x960';
	return '_320x480';
}

export function heightStyle(
	iphoneXSStyle = {},
	iphoneXStyle = {},
	regularStyle = {},
	smallStyle = {}
) {
	if (height > 890) return iphoneXSStyle;
	else if (height > 810) return iphoneXStyle;
	else if (height > 660) return regularStyle;
	return smallStyle;
}

export function ipadStyle(ipadStyle = {}) {
	return height > 1000 ? ipadStyle : {};
}

export const website = `https://www.loxclubapp.com`;
export const tosLink = `${website}/tos`;
export const privacyLink = `${website}/privacy-policy`;
export const eulaLink = `https://loxclub-landing-page.now.sh/`;
export const interestedInOptions = [
	{ name: 'Men', value: 'male' },
	{ name: 'Women', value: 'female' },
	{ name: 'Everyone', value: 'everyone' },
];

export const genderOptions = [
	{ name: 'Man', value: 'male' },
	{ name: 'Woman', value: 'female' },
	// { name: 'Other', value: 'other' },
];

export const testNumbers = [
	'+1444-444-4444',
	'+1555-555-5555',
	'+1111-111-1111',
	'+1111-111-1112',
	'+1111-111-1113',
	'+1111-111-1114',
	'+1111-111-1115',
	'+1111-111-1116',
	'+1333-333-3333',
	'+1222-222-2222',
];

export const testNumbersForApp = [
	'+11111111111',
	'+11111111112',
	'+11111111113',
	'+13333333333',
	'+12154295512',
];

export const email = 'hey@loxclubapp.com';

export const speakeasyCopy1 = `\nMorris and the love of his life Josie Spielman, sharing an appreciation for half-sour pickles, opened ‘Spielman’s Delicatessen’ together in New York City.\n\nBut Morris tragically died that same year. Josie worried she had no business running a deli and considered closing it. \n\nShe felt lucky to have experienced a fairytale kind of love, and wanted to create a space for others to find love as well.`;
export const speakeasyCopyBold = `So she opened up a secret speakeasy hidden within the deli and called it The Lox Club. `;
export const speakeasyCopy2 = `As the password to get in, members would place an order for “pickles”.\n\nFor the next 3 decades, The Lox Club became a gathering place for the Jewish elite: celebrities, politicians, businessmen, mobsters, etc. Non-Jews such as Frank Sinatra and Marilyn Monroe were frequent guests.\n\nIn 1953, The Lox Club was shut down by the police, never to be seen again... until now.`;

export const applicationFields = [
	'firstName',
	'lastName',
	'email',
	'birthday',
	'gender',
	'interestedIn',
	'currentLocation',
	'hometown',
	'instagram',
	'education',
	'profession',
	'companyName',
	'personalHistory',
	'whyJoin',
	'affiliate',
	// 'referralList',
];

export const supportNumber = '13235293116';

export const placeholderPicture =
	'https://firebasestorage.googleapis.com/v0/b/loxclub-d171d.appspot.com/o/global%2Fplaceholder.png?alt=media&token=91735cf3-9067-4eaf-bcf9-6938414dd174';
export const careerHistoryLimit = 450;
export const matchmakerNumber = '13235293116';
export const containerWidth = width * 0.75;

export const TraineeActionName = {
	ACCEPT: 'accept',
	DELAY_ACCEPT: 'delay-accept',
	WAITLIST: 'waitlist',
	PERMANENTLY_WAITLIST: 'Waitlist (Permanent)',
	DELAY_WAITLIST: 'Delay Waitlist',
	DELAY_PERMANENTLY_WAITLIST: 'Delay Permanent Waitlist',
	BLACKLIST: 'blacklist',
	NON_SUPPORTED_COUNTRY: 'Non-Supported Country',
	NON_SUPPORTED_CITY: 'Non-Supported City',
	DRAFT_ACCEPT: 'Draft Accept',
	DRAFT_WAITLIST: 'Draft Waitlist',
	DRAFT_PERMANENTLY_WAITLIST: 'Draft Permanent Waitlist',
};

const noAppsList = [
	`You did it, NAME. You really did it.`,
	`"Where we go from there is a choice I leave to you." - Neo`,
	`Goodbye NAME... for now 😉`,
	`Ok you've reviewed enough apps for today NAME. Go have some wine 🍷`,
	`You did it, NAME. Time to reward yourself with a treat.`,
	`As Gandalf said, 'All we have to decide is what to do with the time that is given us.' Take a break, NAME.`,
	`You’ve done your part, NAME. Time for a breather.`,
	`Nice work, NAME. Now go do something fun.`,
	`As Yoda said, 'Much to learn, you still have.' But for now, rest easy, NAME.`,
	`Mission accomplished, NAME. Take a well-deserved break.`,
	`You’ve done enough for today, NAME. Go have some fun.`,
	`Finished for now, NAME. Treat yourself to something nice.`,
];

export const getNoAppsMessage = (name) => sample(noAppsList).replace('NAME', name);
