import React, { useContext } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { View, Text } from 'react-native';
import { width } from '@constants';
import RNPickerSelect from 'react-native-picker-select';
import { AppsRemainingContext } from '@context/AppsRemainingContext';

const minions = [
	{ value: 'H5O1wl9tjdf1K16UEGFe3uye4N42', label: 'Debra Frenkel' },
	{ value: 'whNMxwHsBAMflTLGTUd0S8DIAXR2', label: 'Blake Fonberg' },
	{ value: 'gFHx1Agv29WtufvsRIShgjTKoT93', label: 'Michelle Chien' },
	{ value: '9hIaAy4O57XNS3mKpyDMYaa6QME3', label: 'Teera Somsak' },
	{ value: 'r9VeWvPxBSVg8XJ2Yl5W5qLlBnn1', label: 'Tali Benchimol' },
	{ value: 'zxYLhvECAmd7rRLLU4XuFs1GN6H3', label: 'Keren Karshenboym' },
	{ value: 'RuSaPFEXtiMaFZzWcMaBPmfUKXC2', label: 'Lisa Kevitch' },
	{ value: 'DBpRGemP8VPiJutHm2r2JozR41c2', label: 'Meghna Upadhyay' },
];

function FilterByMinion({
	onChange,
	filterByMinionId,
}: {
	onChange: (id: string) => void;
	filterByMinionId: string;
}) {
	const { minionCount } = useContext(AppsRemainingContext);

	return (
		<View style={styles.container}>
			<View>
				<RNPickerSelect
					style={{ inputWeb: styles.inputWeb }}
					placeholder={{ label: 'Sort by minion' }}
					onValueChange={(value) => onChange(value)}
					items={minions}
					value={filterByMinionId}
				/>
				{Boolean(filterByMinionId) && (
					<Text style={styles.count}>
						Apps remaining: <Text style={styles.bold}>{minionCount}</Text>
					</Text>
				)}
			</View>
		</View>
	);
}

const styles = EStyleSheet.create({
	container: {
		width,
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: 30,
		paddingLeft: 10,
	},
	inputWeb: {
		fontFamily: '$main',
		fontSize: 16,
		color: '$darkBlue',
		paddingVertical: 12,
		borderWidth: 1,
		borderColor: 'gray',
		borderRadius: 4,
	},
	count: {
		marginTop: 5,
		fontFamily: '$main',
		fontSize: 16,
	},
	bold: {
		fontFamily: '$bold',
	},
});

export default FilterByMinion;
